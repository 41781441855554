<template>
  <div class="show_meeting">
    <div
      class="pages_header member-tablet-view d-flex align-items-center justify-content-between"
    >
      <h3 class="page_title my-2">بيانات الإجتماع</h3>
      <div class=" my-2">
        <a
          v-if="!userData.roles.includes('viewer')"
           class="btn_Green btn_main" @click="$router.push('/new_meeting')">
          <i class="fas fa-plus"></i>
                    <span>
             
            إجتماع جديد
          
          </span>
        </a>
      </div>
    </div>

    <div class="form_container meeting-link">
      <div class="form_header row">
        <div class="meeting-input col-md-7">
          <div class="title">
            <span>{{ meeting.title }}</span>
          </div>
          <div class="meeting-content d-flex">
            <div class="time">
              <img class="icon_img" src="/media/svg/clock.svg" />
              <span class="tajawal-medium meeting-time"
                ><span class="tajawal-medium">{{
                  meeting.start_time | moment("h:mm a")
                }}</span>
                -
                <span class="tajawal-medium">
                  {{ meeting.end_time | moment("h:mm a") }}</span
                ></span
              >
            </div>
            <div class="meeting-date">
              <img class="icon_img" src="/media/svg/calendar.svg" />
              <span class="tajawal-medium">
                {{ meeting.start_time | moment("Do MMMM  YYYY") }}</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-5 justify-content-end d-flex" style="padding: 8px">
          <!-- <a  class="btn_brimary create_btn    m-1">
           
           
          </a> -->
          <button
            type="button"
            class="btn_Green btn_main create_btn    m-1"
            v-clipboard:copy="`${base_URL}/#/mt/${meeting.slug}`"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <i style="color: white" class="fas fa-copy"></i>
           
          </button>
          <button
            type="button"
            class="btn_brimary create_btn    m-1"
            @click="
              $router.push(`/mt/${meeting.slug}`)
            "
          >
            <span> الدخول للإجتماع </span>
          </button>
          <a
           v-if="!userData.roles.includes('viewer')"
            @click="$router.push(`/edit_meeting/${meeting.slug}`)"
            class="btn_second create_btn m-1"
          >
            <i style="color: white" class="fas fa-edit"></i>
            <span> تعديل </span>
          </a>
        </div>
      </div>
      <div class="form_body">
        <div class="metting_info d-flex">
          <ul class="header_list">
            <li>
              <span>ربط الاجتماع بمبادرة سابقة</span>
              <span>(اختياري)</span>
            </li>
            <li>
              <span>طبيعة الاجتماع</span>
              <span></span>
            </li>
            <li>
              <span>نوع الإجتماع</span>
              <span></span>
            </li>
            <!-- <li>
              <span>ربط الاجتماع ب</span>
              <span>(اختياري)</span>
            </li> -->
          </ul>
          <ul class="info_list">
            <li>
              <span>{{
                meeting.parent_meeting.length
                  ? meeting.parent_meeting[0].title
                  : "لا يوجد"
              }}</span>
            </li>
            <li>
              <i class="fas fa-check"></i>
              <span>{{
                meeting.nature_of_meeting == "client_meetning"
                  ? "إجتماع مع عميل"
                  : "إجتماع داخلى"
              }}</span>
            </li>
            <li>
              <i class="fas fa-check"></i>
              <span>{{
                meeting.meeting_type == "public"
                  ? "عام (يمكن دخول أخرين خلال الاجتماع)"
                  : "خاص"
                  
              }}</span>
            </li>
            <!-- <li>
              <span>{{
                meeting.task_link ? meeting.task_link : "لايوجد"
              }}</span>
            </li> -->
          </ul>
        </div>
        <div class="invited_usesr">
          <div class="meeting-input mt-16">
            <div class="title">
              <span>المدعوين</span>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-4"
              v-for="element in meeting.users"
              :key="element.name"
            >
              <div class="user_data">
                <img :src="element.avatar" />
                <span class="user_name">{{ element.name }}</span>
              </div>
            </div>
            <div
              class="col-md-4"
              v-for="element in meeting.groups"
              :key="element.name"
            >
              <div class="user_data">
                <img :src="element.image" />
                <span class="user_name">{{ element.name }}</span>
                <div class="list_actions" style="top: 5px">
                  <b-dropdown
                    v-if="element.users.length"
                    position="is-bottom-left"
                    class="dropdownList m-0"
                    aria-role="list"
                  >
                    <template #trigger>
                      <a class="navbar-item p-0" role="button">
                        <div class="item-eye">
                          <i class="fas fa-eye"></i>
                        </div>
                      </a>
                    </template>
                    <b-dropdown-item
                      paddingless
                      aria-role="listitem"
                      v-for="user in element.users"
                      :key="user.id"
                    >
                      <div class="user_data mb-2">
                        <img :src="user.avatar" />
                        <span class="user_name">{{ user.name }}</span>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown
                    v-else
                    position="is-bottom-left"
                    class="dropdownList m-0"
                    aria-role="list"
                  >
                    <template #trigger>
                      <a class="navbar-item p-0" role="button">
                        <div class="item-eye">
                          <i class="fas fa-eye"></i>
                        </div>
                      </a>
                    </template>
                    <b-dropdown-item paddingless aria-role="listitem">
                      <div class="user_data mb-2">
                        <span class="user_name">لا يوجد اعضاء</span>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="meeting-input mt-16">
          <div class="title">
            <span>المهام المطلوبة</span>
          </div>
          <div class="d-flex mb-5">
            <!-- <div class="col-md-3 to_drop_in">
              <div class="list-group">
                <div v-for="element in meeting.users" :key="element.name">
                  <div class="user_data">
                    <img :src="element.avatar" />
                    <span class="user_name">{{ element.name }}</span>
                  </div>
                </div>
                <div v-for="element in meeting.group" :key="element.name">
                  <div class="user_data">
                    <img :src="element.image" />
                    <span class="user_name">{{ element.name }}</span>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="input-search position-relative col-md-9">
              <input
                style="height: 60px"
                class="form-control"
                name="meeting-link"
                id=""
                placeholder="المهمة المطلوب تنفيذها"
              />
              <button class="add_input_icon">
                <img src="/media/svg/plus.svg" />
              </button>
            </div> -->
          </div>
        </div>

        <div class="tasks" v-if="meeting.tasks.length">
          <ul>
            <li v-for="task in meeting.tasks" :key="task.id">
              <div class="d-block p-4">
                <div class="d-flex position_relative">
                  <b-dropdown
                    position="is-bottom-left"
                    class="dropdownList"
                    aria-role="list"
                  >
                    <template #trigger>
                      <a
                        class="navbar-item p-0"
                        role="button"
                        v-if="task.users && task.users.length"
                      >
                        <div
                          v-if="task.users.length"
                          class="user_data bg_gray m-0"
                        >
                          <img :src="task.users[0].avatar" />
                          <span class="user_name ml-2">{{
                            task.users[0].name
                          }}</span>
                          <b-icon icon="menu-down mr-auto"></b-icon>
                        </div>
                        <div v-else class="user_data bg_gray m-0">
                          <img :src="task.groups[0].image" />
                          <span class="user_name ml-2">{{
                            task.groups[0].name
                          }}</span>
                          <b-icon icon="menu-down mr-auto"></b-icon>
                        </div>
                      </a>
                    </template>
                    <b-dropdown-item
                      paddingless
                      aria-role="listitem"
                      v-for="item in task.users.slice(1)"
                      :key="item.id"
                    >
                      <div class="user_data mb-2">
                        <img :src="item.avatar" />
                        <span class="user_name">{{ item.name }}</span>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item
                      paddingless
                      aria-role="listitem"
                      v-for="item in task.groups"
                      :key="item.id"
                    >
                      <div class="user_data mb-2">
                        <img :src="item.image" />
                        <span class="user_name">{{ item.name }}</span>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <h6 class="col-md-9">
                    {{ task.required }}
                  </h6>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="tasks" v-else>لم يتم إضافة مهام</div>
        <div class="metting_notes">
          <div class="meeting-input mt-16">
            <div class="title">
              <span>ملخص الإجتماع</span>
            </div>
          </div>
          <div class="text_editor">
            <wysiwyg
              v-if="meeting.note"
              placeholder=""
              v-model="meeting.note.note"
            />
            <wysiwyg
              v-else
              disabled
              placeholder="لم يتم إدخال ملخص للإجتماع حتى الأن"
            />
          </div>
        </div>
        <!-- <div class="invited_usesr">
          <div class="row" :list="list3" group="people" @change="log">
            <div class="col-md-6" v-for="element in list1" :key="element.name">
              <div class="d-flex">
                <vs-checkbox checked style="height: 45px;" color="#858585"></vs-checkbox>
                <div class="user_data" style="width: 320px;">
                  <img :src="element.img" />
                  <span class="user_name">{{ element.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="invited_usesr">
          <div class="meeting-input mb-16">
            <div class="title">
              <span>ارسال الاجتماع عن طريق</span>
            </div>
          </div>
          <div class="row">
            <div
              v-if="meeting.sms_option == 1"
              class="col-md-3 col-12"
              style="font-size: 15px; color: #1c5f95; font-weight: bold"
            >
              <i
                style="
                  background: #858585;
                  color: white;
                  padding: 2px;
                  border-radius: 4px;
                  margin-left: 10px;
                "
                class="fas fa-check"
              ></i>
              SMS
            </div>
            <div
              v-if="meeting.whatsapp_option == 1"
             class="col-md-3 col-12"
              style="font-size: 15px; color: #1c5f95; font-weight: bold"
            >
              <i
                style="
                  background: #858585;
                  color: white;
                  padding: 2px;
                  border-radius: 4px;
                  margin-left: 10px;
                "
                class="fas fa-check"
              ></i>
              Whatsapp
            </div>
            <div
              v-if="meeting.email_option == 1"
             class="col-md-3 col-12"
              style="font-size: 15px; color: #1c5f95; font-weight: bold"
            >
              <i
                style="
                  background: #858585;
                  color: white;
                  padding: 2px;
                  border-radius: 4px;
                  margin-left: 10px;
                "
                class="fas fa-check"
              ></i>
              البريد الإلكترونى
            </div>
          </div>
          <div
            v-if="
              meeting.sms_option == 0 &&
              meeting.whatsapp_option == 0 &&
              meeting.email_option == 0
            "
          >
            لم يتم إختيار وسيلة لإرسال الإجتماع
          </div>
        </div>

        <div class="col-md-12 col-12 form_footer">
          <div class="d-flex justify-content-end">
            <button
              class="btn_Green btn_main"
              @click="$router.push('/meetings_list')"
              aria-label="saveasdraft"
            >
              <span>حفظ البيانات</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import draggable from "vuedraggable";
import meetings from "@/http/requests/meetings";

export default {
  name: "ShowMeeting",
  data() {
    return {
      meeting: {},
      myHTML: undefined,
      switch2: undefined,
      metting_time: "",
      Search_metting_value: undefined,
      value: undefined,
      e1: 1,
      base_URL: "",
      userData:{},
    };
  },
  // components: {
  //   draggable,
  // },
  methods: {
    onCopy(text) {
      this.$vs.notify({
        text: "تم نسخ الرابط بنجاح",
        color: "success",
      });
    },
    onError() {
      alert("Failed to copy texts");
    },
    getMeeting() {
      this.meeting = {};
      meetings.getSingleMeeting(this.$route.params.id).then((res) => {
        this.meeting = res.data.data;
      });
    },
  },
  created() {
    this.getMeeting();
    this.base_URL = window.location.origin;
     this.userData = JSON.parse(localStorage.getItem("User_data"));
  },
};
</script>

